import React from 'react';
import logo from './assets/logo.svg';
import { ProductCard } from './features/productCard/ProductCard';
import './App.css';
import { Model } from './features/model/Model';
import { ProductColor } from './features/productColor/ProductColor';
import { Loader } from './features/loader/Loader';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <a href="https://www.nike.com" target="_blank" rel="noopener noreferrer" title="Go to Nike Site">
          <img src={logo} className="app-logo" alt="logo" />
        </a>
      </header>
      <div className="card">
        <ProductCard />
      </div>
      <div className="loader">
        <Loader />
      </div>
      <div className="scene">
        <Model />
      </div>
      <div className="colors">
        <ProductColor />
      </div>
      <footer className="footer">
        Tapmod Studio 2020
      </footer>
    </div>
  );
}

export default App;
