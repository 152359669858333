import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";


export interface ModelState {
  isLoading: boolean;
}

const initialState: ModelState = {
  isLoading: false
};

export const slice = createSlice({
  name: "model",
  initialState,
  reducers: {
    setStartLoading: (state) => {
      state.isLoading = true;
    },
    setStopLoading: (state) => {
      state.isLoading = false;
    }
  }
});

export const { setStartLoading, setStopLoading } = slice.actions;

export const selectIsLoading = (state: RootState) => state.model.isLoading;

export default slice.reducer;
