import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Loader.module.css';
import { selectPage, LoadingPage } from './loaderSlice';

import line from '../../assets/loader/line.svg';
import spinner from '../../assets/loader/spinner.gif';
import n1 from '../../assets/loader/n1.svg';
import n2 from '../../assets/loader/n2.svg';
import n3 from '../../assets/loader/n3.svg';

export function Loader() {
  const page = useSelector(selectPage);

  if (page === LoadingPage.Hidden) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header} >
        <h2 className={styles.title}>METCON 3</h2>
        <div className={styles.descr}>
          The Nike Metcon 3 Men’s Training Shoe is ready for your most demanding
          workouts - from wall exercises and rope climbs to sprinting and lifting.
        </div>
      </div>
      <div className={styles.num}>
        {page === LoadingPage.Spinner && (
          <img src={spinner} alt="Loading..." />
        )}
        {page === LoadingPage.Page1 && (
          <img src={n1} alt="1" className={styles.page1} />
        )}
        {page === LoadingPage.Page2 && (
          <img src={n2} alt="2" />
        )}
        {page === LoadingPage.Page3 && (
          <img src={n3} alt="3" />
        )}
      </div>
      {(page === LoadingPage.Page1 || page === LoadingPage.Page2 || page === LoadingPage.Page3) && (
        <img src={line} className={styles.line} alt="" />
      )}
    </div>
  );
}
