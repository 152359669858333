import { createSlice } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";

export interface ProductCardState {
  size: number;
  quantity?: number;
  price: number;
  total: number;
}

const initialState: ProductCardState = {
  size: 9,
  quantity: 1,
  price: 170,
  total: 170
};

const getFakePriceFromSize = (size: number) => (170 - 27) + size * 3;

export const slice = createSlice({
  name: "productCard",
  initialState,
  reducers: {
    incQty: state => {
      state.quantity = state.quantity ?? 1;
      state.quantity += 1;
    },
    addToCart: (state, action) => {
      alert(`SIZE:${state.size}; QTY:${state.quantity}`);
      Object.assign(state, initialState);
    },
    onChange: (state, action) => {
      Object.assign(state, action.payload);
      state.price = getFakePriceFromSize(state.size);
      state.total = (state.quantity ?? 0) * state.price;
    }
  }
});

export const { incQty, addToCart, onChange } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const addToCartAsync = (): AppThunk => dispatch => {
  setTimeout(() => {
    dispatch(addToCart(null));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.productCard.value)`
export const selectOptions = (state: RootState) => state.productCard;

export default slice.reducer;
