import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../../app/store";
import { ProductColors } from "../../consts/Colors";

interface ProductColorState {
  value: ProductColors;
}

const initialState: ProductColorState = {
  value: ProductColors.black_gold
};

export const slice = createSlice({
  name: "productColor",
  initialState,
  reducers: {
    select: (state, action: PayloadAction<ProductColors>) => {
      state.value = action.payload;
    }
  }
});

export const { select } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const selectAsync = (value: ProductColors): AppThunk => dispatch => {
  setTimeout(() => {
    dispatch(select(value));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.productColor.value)`
export const selectColor = (state: RootState) => state.productColor.value;

export default slice.reducer;
