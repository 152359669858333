import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  select,
  selectColor,
} from './productColorSlice';
import styles from './ProductColor.module.css';
import { ColorsTable } from './../../consts/Colors';



export function ProductColor() {
  const color = useSelector(selectColor);
  const dispatch = useDispatch();

  const colors = ColorsTable;

  const listItems = Object.entries(colors).map(
    ([key, value]) =>
      <div className={styles.item + ` ${color === key ? styles.selected : ""}`} key={key} onClick={() => dispatch(select(key as any))}>
        <value.svg title={value.title}
          preserveAspectRatio="xMidYMid meet"
          className={styles.icon}
          width="48"
          height="48"
          viewBox="4 0 48 48" />
      </div>
  );

  return (
    <div className={styles.productColor}>
      {listItems}
    </div>
  );
}
