/** boilerplate code, not used now */

const colors = {
  primary: '#333',
  white: '#eee',
  background: '#f0f2f4'
};

const spaceRef = ['0px', '5px', '15px', '30px'];
const space = {
  small: spaceRef[1],
  medium: spaceRef[2],
  large: spaceRef[3]
};
const breakpoints = ['320px', '480px', '768px', '992px', '1200px'];

const mediaQueries = {
  xsmall: `@media screen and (min-width: ${breakpoints[0]})`,
  small: `@media screen and (min-width: ${breakpoints[1]})`,
  medium: `@media screen and (min-width: ${breakpoints[2]})`,
  large: `@media screen and (min-width: ${breakpoints[3]})`,
  xlarge: `@media screen and (min-width: ${breakpoints[4]})`
};

const fontSizesRef = ['12px', '14px', '16px', '20px', '24px', '32px'];
const fontSizes = {
  xsmall: fontSizesRef[0],
  small: fontSizesRef[1],
  medium: fontSizesRef[2],
  large: fontSizesRef[3],
  xlarge: fontSizesRef[4],
  xxlarge: fontSizesRef[5]
};


const modelHeight = '600px';

export default {
  colors,
  fontSizes,
  space,
  breakpoints,
  mediaQueries,
  modelHeight
};
