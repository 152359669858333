import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOptions,
  onChange,
  addToCart
} from './productCardSlice';
import styles from './ProductCard.module.css';

export function ProductCard() {
  const opt = useSelector(selectOptions);

  const dispatch = useDispatch();
  return (
    <div className={styles.productCard}>
      <h2 className={styles.header}>
        NIKE METCON 3 iD
      </h2>
      <div className={styles.header2}>
        Men's training shoe
      </div>
      <div className={styles.price}>
        ${opt.total}
      </div>
      <div className={styles.header3}>
        Durable stability, customized
      </div>
      <div className={styles.description}>
        The fully updated nike Metcon 3 iD Training Shoe is ready for your most damanding workouts - from well exercised rope climbs to
      </div>
      <div className={styles.addOptions}>
        <span className={styles.sizeLabel}>SIZE</span>
        <input
          type="number"
          className={styles.sizeInput}
          aria-label="Size"
          min="6"
          max="15"
          onChange={((v) => dispatch(onChange({ size: v.target.value })))}
          value={opt.size}
        />

        <span className={styles.qtyLabel}>QTY</span>
        <input
          type="number"
          className={styles.qtyInput}
          aria-label="QTY"
          min="1"
          max="10"
          onChange={((v) => dispatch(onChange({ quantity: v.target.value })))}
          value={opt.quantity}
        />
      </div>
      <div className={styles.addToCart}>
        <button
          className={styles.button}
          onClick={() => dispatch(addToCart({}))}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
}
