import theme from "./theme";

export const modelOptions = {
  camera: 0,
  autostart: 0,
  autospin: 0,
  preload: 1,
  annotations_visible: 1,
  animation_autoplay: 1,
  ui_infos: 0,
  ui_annotations: 0,
  ui_animations: 0,
  ui_controls: 0,
  ui_stop: 0,
  ui_help: 0,
  ui_hint: 0,
  ui_general_controls: 0,
  ui_watermark: 0,
  transparent: 0,
  scrollwheel: 1,
  double_click: 0,

  ui_color: theme.colors.background,
};

export const modelUid = 'cb5b688484884313b82c5a66fb3e32cf';
