import React from 'react';
import { useSelector } from 'react-redux';
import { useSketchfab, useMaterialChooser } from './Model.hooks';

import styles from './Model.module.css';

import { selectIsLoading } from './modelSlice';
import { modelOptions, modelUid } from '../../consts/sketchfab';

export function Model() {
  const iframeId = 'sweater';
  const isLoading = useSelector(selectIsLoading);

  const [sketchfabApi] = useSketchfab({
    uid: modelUid,
    iframeId: iframeId,
    options: modelOptions
  });


  // if (sketchfabApi) {
  //   sketchfabApi.addEventListener(
  //     'click',
  //     function (event) {
  //       // eslint-disable-next-line no-console
  //       console.log(event.material);
  //     },
  //     { pick: 'slow' }
  //   );
  // }

  useMaterialChooser(sketchfabApi || undefined);

  const attrs = {
  };
  return (
    <iframe
      id={iframeId}
      title="Sketchfab Model"
      className={`${styles.frame} ${isLoading ? 'loading' : ''}`}
      {...attrs}
    />
  );
}
