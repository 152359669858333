import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";


export enum LoadingPage {
  Hidden = 'Hidden',
  Spinner = 'Spinner',
  Page1 = 'Page1',
  Page2 = 'Page2',
  Page3 = 'Page3'
}

export interface LoaderState {
  page: LoadingPage;
}

const initialState: LoaderState = {
  // shows page1 on start
  page: LoadingPage.Spinner,
};

export const slice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    gotoPage: (state, action: PayloadAction<LoadingPage>) => {
      state.page = action.payload;
    },
  }
});

export const { gotoPage } = slice.actions;

export const selectPage = (state: RootState) => state.loader.page;

export default slice.reducer;
