import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import productColorReducer from "../features/productColor/productColorSlice";
import productCardReducer from "../features/productCard/productCardSlice";
import modelReducer from "../features/model/modelSlice";
import loaderReducer from "../features/loader/loaderSlice";

export const store = configureStore({
  reducer: {
    productColor: productColorReducer,
    productCard: productCardReducer,
    model: modelReducer,
    loader: loaderReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
