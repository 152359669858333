
//type values = "grey_fabric" | "black_fabric" | string;

type val = {
    [key: string]: string;
};

type MaterialValues = {
    [material: string]: val;
};

// from Nike Metcon 3 parameters.xlsx
/**
 * Parameters for materials for each color.
 * Using: MaterialParameters[`materialName`][`colorName`] => textureName (?)
 */
export const MaterialParameters: MaterialValues = {
    "back_stripe": {
        "white_red": "grey_fabric",
        "white_blue": "black_fabric",
        "black": "black_fabric",
        "gold_reg": "black_fabric",
        "grey": "grey_fabric",
        "black_gold": "black_fabric"
    },
    "cloth_inside_1": {
        "white_red": "grey_fabric",
        "white_blue": "grey_fabric",
        "black": "black_fabric",
        "gold_reg": "red_fabric",
        "grey": "grey_fabric",
        "black_gold": "black_fabric"
    },
    "cloth_inside_2": {
        "white_red": "grey_hex_pattern",
        "white_blue": "white_hex_pattern",
        "black": "black_hex_pattern",
        "gold_reg": "red_hex_pattern",
        "grey": "grey_hex_pattern",
        "black_gold": "black_hex_pattern"
    },
    "laces": {
        "white_red": "white_laces",
        "white_blue": "white_laces",
        "black": "black_laces",
        "gold_reg": "black_laces",
        "grey": "grey_laces",
        "black_gold": "black_laces"
    },
    "wires": {
        "white_red": "white_laces",
        "white_blue": "black_laces",
        "black": "black_laces",
        "gold_reg": "black_laces",
        "grey": "grey_laces",
        "black_gold": "gold_laces"
    },
    "label_inside": {
        "white_red": "blue_label",
        "white_blue": "white_label",
        "black": "white_label",
        "gold_reg": "white_label",
        "grey": "black_label",
        "black_gold": "white_label"
    },
    "metcon": {
        "white_red": "blue_metcon",
        "white_blue": "white_metcon",
        "black": "black_metcon",
        "gold_reg": "red_metcon",
        "grey": "black_metcon",
        "black_gold": "black_metcon"
    },
    "metcon_3": {
        "white_red": "grey_metcon3",
        "white_blue": "blue_metcon3",
        "black": "black_metcon3",
        "gold_reg": "gold_metcon3",
        "grey": "grey_metcon3",
        "black_gold": "black_metcon3"
    },
    "shoe_front": {
        "white_red": "white_out_cloth",
        "white_blue": "white_out_cloth",
        "black": "light_black_out_cloth",
        "gold_reg": "gold_out_cloth",
        "grey": "light_grey_out_cloth",
        "black_gold": "light_black_out_cloth"
    },
    "shoe_back": {
        "white_red": "dark_grey_out_cloth",
        "white_blue": "dark_grey_out_cloth",
        "black": "dark_black_out_cloth",
        "gold_reg": "red_out_cloth",
        "grey": "dark_grey_out_cloth",
        "black_gold": "dark_black_out_cloth"
    },
    "sole_back_1": {
        "white_red": "white_plastic",
        "white_blue": "blue_plastic",
        "black": "black_plastic",
        "gold_reg": "red_plastic",
        "grey": "grey_plastic",
        "black_gold": "white_plastic"
    },
    "sole_back_2": {
        "white_red": "blue_plastic",
        "white_blue": "blue_plastic",
        "black": "black_plastic",
        "gold_reg": "black_plastic",
        "grey": "black_plastic",
        "black_gold": "black_plastic"
    },
    "sole_main": {
        "white_red": "brown_plastic",
        "white_blue": "black_plastic",
        "black": "white_plastic",
        "gold_reg": "gold_plastic",
        "grey": "white_plastic",
        "black_gold": "white_plastic"
    },
    "stripe": {
        "white_red": "grey_stripe",
        "white_blue": "grey_stripe",
        "black": "black_stripe",
        "gold_reg": "red_stripe",
        "grey": "grey_stripe",
        "black_gold": "black_stripe"
    },
    "swoosh_inside": {
        "white_red": "blue_plastic",
        "white_blue": "red_plastic",
        "black": "black_plastic",
        "gold_reg": "black_plastic",
        "grey": "black_plastic",
        "black_gold": "white_plastic"
    },
    "swoosh_side": {
        "white_red": "blue_swoosh",
        "white_blue": "red_swoosh",
        "black": "white_swoosh",
        "gold_reg": "gold_swoosh",
        "grey": "black_swoosh",
        "black_gold": "gold_swoosh"
    },
    "swoosh_sole": {
        "white_red": "white_plastic",
        "white_blue": "red_plastic",
        "black": "black_plastic",
        "gold_reg": "red_plastic",
        "grey": "black_plastic",
        "black_gold": "black_plastic"
    },
    "tongue_1": {
        "white_red": "white_plastic",
        "white_blue": "white_plastic",
        "black": "black_plastic",
        "gold_reg": "black_plastic",
        "grey": "grey_plastic",
        "black_gold": "black_plastic"
    },
    "tongue_2": {
        "white_red": "white_fabric",
        "white_blue": "white_fabric",
        "black": "black_fabric",
        "gold_reg": "black_fabric",
        "grey": "grey_fabric",
        "black_gold": "black_fabric"
    },
    "tongue_3": {
        "white_red": "white_plastic",
        "white_blue": "white_plastic",
        "black": "white_plastic",
        "gold_reg": "black_plastic",
        "grey": "grey_plastic",
        "black_gold": "white_plastic"
    },
    "tongue_4": {
        "white_red": "white_noise",
        "white_blue": "white_noise",
        "black": "black_noise",
        "gold_reg": "black_noise",
        "grey": "grey_noise",
        "black_gold": "black_noise"
    },
    "nike": {
        "white_red": "grey_plastic",
        "white_blue": "blue_plastic",
        "black": "white_plastic",
        "gold_reg": "gold_plastic",
        "grey": "black_plastic",
        "black_gold": "white_plastic"
    },
    "sole_pattern": {
        "white_red": "brown_sole_pattern",
        "white_blue": "black_sole_pattern",
        "black": "white_sole_pattern",
        "gold_reg": "gold_sole_pattern",
        "grey": "white_sole_pattern",
        "black_gold": "white_sole_pattern"
    }
}