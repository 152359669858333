import { useEffect, useRef } from "react";
import SketchfabWrapper from "sketchfab-api-wrapper";
import { useDispatch, useSelector } from "react-redux";
import { setStartLoading, setStopLoading } from "./modelSlice";
import { MaterialParameters } from "../../consts/Parameters";
import { selectColor } from "../productColor/productColorSlice";
import { gotoPage, LoadingPage } from "../loader/loaderSlice";
import { timeouts } from './../../consts/timeouts';



/** Initializes sketchfab api */
export const useSketchfab = (arg: {
  uid: string,
  iframeId: string,
  options: any
}) => {
  const sketchFabApiRef = useRef<SketchfabWrapper>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStartLoading());
    dispatch(gotoPage(LoadingPage.Spinner));

    const startCountdownStory = () => {
      return new Promise((resolve, reject) => {
        dispatch(gotoPage(LoadingPage.Page1));
        setTimeout(() => {
          dispatch(gotoPage(LoadingPage.Page2));
          setTimeout(() => {
            dispatch(gotoPage(LoadingPage.Page3));
            setTimeout(() => {
              resolve();
            }, timeouts.page);
          }, timeouts.page);
        }, timeouts.page);
      });
    }

    const init = async () => {
      const loadWrapperPromise = new SketchfabWrapper({
        iframeQuery: `#${arg.iframeId}`,
        uid: arg.uid,
        options: arg.options,
        version: "1.7.1",
        useSingleton: false
      });

      const wrapper = await loadWrapperPromise.init();

      const api = { ...wrapper, ...wrapper.api };
      sketchFabApiRef.current = api;
      window.api = api;

      await startCountdownStory();
      dispatch(gotoPage(LoadingPage.Hidden));
      dispatch(setStopLoading());
    };
    init();
  }, [arg.iframeId, arg.options, arg.uid, dispatch]);

  return [sketchFabApiRef.current];
};

/** Sets materials using selected color */
export const useMaterialChooser = (apiRef?: SketchfabWrapper) => {

  const color = useSelector(selectColor);
  useEffect(() => {
    if (!apiRef) {
      return;
    }

    const api = apiRef;
    var entries = Object.entries(MaterialParameters);

    var materials = entries.map(entry => {
      var name = entry[0];
      var refName = MaterialParameters[name][color]
      if (!refName) { console.warn(`Material '${name}' parameter for color ${color} not set.`); return {}; }
      return { name, refName }
    });

    materials.forEach(material => {
      if (!material.refName) return;
      api.swapMaterial({ source: material.refName, target: material.name });
    });

  }, [apiRef, color]);
}