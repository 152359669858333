

// import color11 from '../assets/colors/color1.svg';
import { ReactComponent as color1 } from '../assets/colors/color1.svg';
import { ReactComponent as color2 } from '../assets/colors/color2.svg';
import { ReactComponent as color3 } from '../assets/colors/color3.svg';
import { ReactComponent as color4 } from '../assets/colors/color4.svg';
import { ReactComponent as color5 } from '../assets/colors/color5.svg';
import { ReactComponent as color6 } from '../assets/colors/color6.svg';

export enum ProductColors {
    white_red = "white_red",
    white_blue = "white_blue",
    black = "black",
    gold_reg = "gold_reg",
    grey = "grey",
    black_gold = "black_gold"
}


type ColorValues = {
    svg: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
    }>, title: string
};

export const ColorsTable: {
    [key: string]: ColorValues
} = {
    [ProductColors.black_gold]: {
        svg: color6,
        title: "Black Gold",
    },
    [ProductColors.white_blue]: {
        svg: color2,
        title: "White Blue"
    },
    [ProductColors.white_red]: {
        svg: color1,
        title: "White Red"
    },

    [ProductColors.black]: {
        svg: color3,
        title: "Black"
    },
    [ProductColors.gold_reg]: {
        svg: color4,
        title: "Gold Reg",
    },
    [ProductColors.grey]: {
        svg: color5,
        title: "Grey",
    }
};